import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./store/combineReducer";
import PageLoading from "./components/common/PageLoading";

const store = createStore(rootReducer);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Suspense fallback={<div><PageLoading /></div>}>
            {/* <UserProvider> */}
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#007dc1",
                  colorSuccess: "#0fff77",
                  colorInfo: "#007dc1",
                },
              }}
            >
              <App />
            </ConfigProvider>
            {/* </UserProvider> */}
          </Suspense>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
